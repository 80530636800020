<template>
	<div class="home">
		<div class="content-box hidden-sm-and-down" v-if="webMode === 1">
            <img :src="adLink.pc" alt="" class="page-contract-bg">
            <div class="page-content-box">
                <div class="page-contract-content">
                    <el-row :gutter="150">
                        <el-col :span="12">
                            <div class="page-contract-content-left">
                                <div class="page-contract-content-title">{{ $t("contractData.messageTitle") }}</div>
                                <div class="page-contract-content-title-desc">{{ $t("contractData.messageTitleDesc") }}</div>
                                <el-row>
                                    <el-col :span="24">
                                        <el-input v-model="surname" :placeholder="$t('contractData.surnamePlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="name" :placeholder="$t('contractData.namePlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="email" :placeholder="$t('contractData.emailPlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="phone" :placeholder="$t('contractData.phonePlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="company" :placeholder="$t('contractData.companyPlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="address" :placeholder="$t('contractData.addressPlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input
                                            class="contract-input-textarea"
                                            type="textarea"
                                            :placeholder="$t('contractData.requirementDescPlaceholder')"
                                            v-model="requirementDesc"
                                            rows="5"
                                            maxlength="100"
                                            show-word-limit
                                        >
                                        </el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input
                                            class="contract-input-textarea"
                                            type="textarea"
                                            :placeholder="$t('contractData.opinionPlaceholder')"
                                            v-model="opinion"
                                            rows="5"
                                            maxlength="250"
                                            show-word-limit
                                        >
                                        </el-input>
                                    </el-col>
                                </el-row>
                                <div class="contract-agreement-content">
                                    <el-checkbox v-model="agreementChecked"></el-checkbox>
                                    <div>
                                        {{ $t("contractData.agreementText1") }}
                                        <font @click="handleToNewPage(agreementUrl)">{{ $t("contractData.agreementText2") }}</font>
                                        {{ $t("contractData.agreementText3") }}
                                        <font @click="handleToNewPage(privacyUrl)">{{ $t("contractData.agreementText4") }}</font>
                                    </div>
                                </div>
                                <div class="btn intro-box-more" @click="handleSubmitMessage">{{ $t("pageDom.submitMessageBtn") }}</div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="page-contract-content-right">
                                <div class="page-contract-content-title">{{ $t("contractData.contractInfoTitle") }}</div>
                                <div class="contract-info-node">{{ $t("contractData.addressContent") }}</div>
                                <div class="contract-info-node">{{ $t("contractData.contractPhone") }}</div>
                                <div class="contract-info-node">{{ $t("contractData.contractFax") }}</div>
                                <div class="contract-info-node">
                                    {{ $t("contractData.contractWx") }}
                                    <img class="wx-share" src="/imgStatic/qrCode.png"/>
                                </div>
                                <baidu-map
                                    :ak="mapAk"
                                    @ready="baiduMapFun"
                                    :scroll-wheel-zoom='true'
                                    class="bm-view"
                                >
                                </baidu-map>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
		</div>
		<div class="mobile-content-box hidden-md-and-up" v-if="webMode === 2">
			<img :src="adLink.h5" alt="" class="page-contract-bg">
            <div class="page-content-box">
                <div class="page-contract-content">
                    <el-row>
                        <el-col :span="24">
                            <div class="page-contract-content-left">
                                <div class="page-contract-content-title">{{ $t("contractData.messageTitle") }}</div>
                                <div class="page-contract-content-title-desc">{{ $t("contractData.messageTitleDesc") }}</div>
                                <el-row>
                                    <el-col :span="24">
                                        <el-input v-model="surname" :placeholder="$t('contractData.surnamePlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="name" :placeholder="$t('contractData.namePlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="email" :placeholder="$t('contractData.emailPlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="phone" :placeholder="$t('contractData.phonePlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="company" :placeholder="$t('contractData.companyPlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input v-model="address" :placeholder="$t('contractData.addressPlaceholder')" class="contract-input"></el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input
                                            class="contract-input-textarea"
                                            type="textarea"
                                            :placeholder="$t('contractData.requirementDescPlaceholder')"
                                            v-model="requirementDesc"
                                            rows="5"
                                            maxlength="100"
                                            show-word-limit
                                        >
                                        </el-input>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-input
                                            class="contract-input-textarea"
                                            type="textarea"
                                            :placeholder="$t('contractData.opinionPlaceholder')"
                                            v-model="opinion"
                                            rows="5"
                                            maxlength="250"
                                            show-word-limit
                                        >
                                        </el-input>
                                    </el-col>
                                </el-row>
                                <div class="contract-agreement-content">
                                    <el-checkbox v-model="agreementChecked"></el-checkbox>
                                    <div>
                                        {{ $t("contractData.agreementText1") }}
                                        <font @click="handleToNewPage(agreementUrl)">{{ $t("contractData.agreementText2") }}</font>
                                        {{ $t("contractData.agreementText3") }}
                                        <font @click="handleToNewPage(privacyUrl)">{{ $t("contractData.agreementText4") }}</font>
                                    </div>
                                </div>
                                <div class="btn intro-box-more" @click="handleSubmitMessage">{{ $t("pageDom.submitMessageBtn") }}</div>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="page-contract-content-right">
                                <div class="page-contract-content-title">{{ $t("contractData.contractInfoTitle") }}</div>
                                <div class="contract-info-node">{{ $t("contractData.addressContent") }}</div>
                                <div class="contract-info-node">{{ $t("contractData.contractPhone") }}</div>
                                <div class="contract-info-node">{{ $t("contractData.contractFax") }}</div>
                                <div class="contract-info-node">
                                    {{ $t("contractData.contractWx") }}
                                    <img class="wx-share" src="/imgStatic/qrCode.png"/>
                                </div>
                                <baidu-map
                                    :ak="mapAk"
                                    @ready="baiduMapFun"
                                    :scroll-wheel-zoom='true'
                                    class="bm-view"
                                >
                                </baidu-map>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

import {
    BaiduMap,
} from 'vue-baidu-map';

import {
	getAd,
    feedSubmit,
} from '@/api/common';

export default {
	name: 'Contract',
	components: {
        BaiduMap,
	},
	data() {
		return {
            pageUrl: '/contract',
            adLink: '',
            surname: '',
            name: '',
            email: '',
            phone: '',
            company: '',
            address: '',
            requirementDesc: '',
            opinion: '',
            agreementChecked: false,
            agreementUrl: 'https://www.johnsoncontrols.com/legal/terms',
            privacyUrl: 'https://www.johnsoncontrols.com/legal/privacy',
            mapAk: 'KSs0pwDoNGe50e7tFuOOmh0Gj2V3GG1v',
		};
	},
	created() {
        this.init();
    },
    mounted() {
	},
	methods: {
		init() {
		},
        getAdFunc() {
			getAd({
				id: this.adId,
			}).then(res => {
				this.adLink = (((res || {}).items || [])[0] || {}).url;
			});
		},
		handleToPage(item) {
			window.open(`/newsDetail?id=${item.id}`);
		},
        handleToNewPage(path) {
            window.open(path);
        },
        handleSubmitMessage() {
            if (
                !this.name
                || !this.surname
                || !this.company
                || !this.email
                || !this.phone
                || !this.address
                || !this.agreementChecked
            ) {
                return;
            }

            const params = {
                firstName: this.name,
                surName: this.surname,
                company: this.company,
                email: this.email,
                phone: this.phone,
                address: this.address,
                requirement: this.requirementDesc,
                opinions: this.opinion,
                ip: '127.0.0.1',
            };

            feedSubmit(params).then(res => {
                console.log(res);
                this.$message({
                    message: res,
                    type: 'success',
                });
			});
        },
        baiduMapFun({ BMap, map }) {
            const point = new BMap.Point(113.502635, 23.588186); // 目标地点坐标
            map.centerAndZoom(point, 16);
            const marker = new BMap.Marker(point); // 创建标注
            map.addOverlay(marker); // 将标注添加到地图中
            const circle = new BMap.Circle(point, 6, {
                strokeColor: 'Red',
                strokeWeight: 6,
                strokeOpacity: 1,
                Color: 'Red',
                fillColor: '#f03',
            });
            map.addOverlay(circle);
        },
	},
	computed: {
        webMode() {
            return this.$store.state.webMode;
        },
        menuConfig() {
			return this.$store.state.menuConfig;
		},
        adId() {
			const { adId } = (this.menuConfig || []).find(item => item.position === 'main' && item.url === this.pageUrl) || {};

			return adId || '';
		},
    },
    watch: {
		adId: {
			immediate: true,
			handler(val) {
                if (val) {
                    this.getAdFunc();
				}
			},
		},
    },
};
</script>

<style scoped lang='scss'>
	.home {
		width: 100%;
		.content-box {
            .page-contract-bg {
                display: block;
                width: 100%;
            }
            .page-contract-content {
                margin: 70px 0 220px;
                .page-contract-content-title {
                    margin-bottom: 30px;
                    font-size: 40px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 40px;
                }
                .page-contract-content-title-desc {
                    margin-bottom: 43px;
                    font-size: 18px;
                    color: #333333;
                    line-height: 24px;
                }
                .contract-input {
                    width: 100%;
                    margin-bottom: 16px;
                    /deep/input {
                        height: 60px;
                        border: 1px solid #E5E5E5;
                        border-radius: 0;
                    }
                    /deep/input::-webkit-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/input:-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/input::-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/input:-ms-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                }
                .contract-input-textarea {
                    width: 100%;
                    margin-bottom: 16px;
                    /deep/textarea {
                        border: 1px solid #E5E5E5;
                        border-radius: 0;
                    }
                    /deep/textarea::-webkit-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/textarea:-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/textarea::-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/textarea:-ms-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                }
                .contract-agreement-content {
                    display: flex;
                    align-items: center;
                    margin: 1px 0 80px;
                    font-size: 18px;
                    color: #333333;
                    line-height: 18px;
                    font {
                        color: #C3002F;
                        text-decoration: none;
                        cursor: pointer;
                    }
                    /deep/.el-checkbox {
                        margin-right: 12px;
                        .el-checkbox__inner {
                            width: 24px;
                            height: 24px;
                        }
                        .el-checkbox__inner::after {
                            width: 6px;
                            height: 14px;
                            left: 7px;
                        }
                        .el-checkbox__inner:hover {
                            border-color: #C3002F;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                            background-color: #C3002F;
                            border-color: #C3002F;
                        }
                    }
                }
                .page-contract-content-left {

                }
                .page-contract-content-right {
                    .contract-info-node {
                        display: flex;
                        font-size: 18px;
                        color: #333333;
                        line-height: 18px;
                        margin-bottom: 25px;
                        .wx-share {
                            width: 115px;
                        }
                    }
                }
            }
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #C3002F;
                cursor: pointer;
                user-select: none;
            }
            .btn:hover {
                background: #a60028;
            }
            .intro-box-more {
                width: 210px;
                height: 68px;
                font-size: 24px;
                font-weight: 500;
                color: #FFFFFF;
            }
            .bm-view {
                width: 470px;
                height: 300px;
            }
        }
		.mobile-content-box {
            .page-contract-bg {
                display: block;
                width: 100%;
            }
            .page-contract-content {
                margin: 40px 0 95px;
                .page-contract-content-title {
                    margin-bottom: 17px;
                    font-size: 28px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 28px;
                }
                .page-contract-content-title-desc {
                    margin-bottom: 30px;
                    font-size: 16px;
                    color: #333333;
                    line-height: 16px;
                }
                .contract-input {
                    width: 100%;
                    margin-bottom: 13px;
                    /deep/input {
                        height: 57px;
                        border: 1px solid #E5E5E5;
                        border-radius: 0;
                    }
                    /deep/input::-webkit-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/input:-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/input::-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/input:-ms-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                }
                .contract-input-textarea {
                    width: 100%;
                    margin-bottom: 16px;
                    /deep/textarea {
                        border: 1px solid #E5E5E5;
                        border-radius: 0;
                    }
                    /deep/textarea::-webkit-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/textarea:-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/textarea::-moz-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                    /deep/textarea:-ms-input-placeholder {
                        font-size: 18px;
                        color: #878787;
                    }
                }
                .contract-agreement-content {
                    display: flex;
                    align-items: center;
                    margin: 4px 0 33px;
                    font-size: 14px;
                    color: #333333;
                    line-height: 18px;
                    font {
                        color: #C3002F;
                    }
                    /deep/.el-checkbox {
                        margin-right: 10px;
                        .el-checkbox__inner {
                            width: 24px;
                            height: 24px;
                        }
                        .el-checkbox__inner::after {
                            width: 6px;
                            height: 14px;
                            left: 7px;
                        }
                        .el-checkbox__inner:hover {
                            border-color: #C3002F;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                            background-color: #C3002F;
                            border-color: #C3002F;
                        }
                    }
                }
                .page-contract-content-left {

                }
                .page-contract-content-right {
                    padding-top: 85px;
                    .page-contract-content-title {
                        margin-bottom: 30px;
                    }
                    .contract-info-node {
                        display: flex;
                        font-size: 17px;
                        color: #333333;
                        line-height: 18px;
                        margin-bottom: 25px;
                        .wx-share {
                            width: 115px;
                        }
                    }
                }
            }
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #C3002F;
                cursor: pointer;
                user-select: none;
            }
            .intro-box-more {
                height: 57px;
                font-size: 21px;
                font-weight: 500;
                color: #FFFFFF;
            }
            .bm-view {
                width: 100%;
                height: 226px;
            }
        }
	}
</style>
